<template>
  <div>
    <div class="container my-5">
      <div class="row">
        <div
          class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-3"
        >
          <div class="card shadow">
            <div class="card-body p-5">
              <h3 class="cl-primary f-bold text-center">
                Mimo <span class="cl-secondary"> Login</span>
              </h3>
              <form class="mt-4" @submit.prevent="postLogin">
                <div class="form-group">
                  <!-- <label for="email">Email address</label> -->
                  <input
                    type="email"
                    class="form-control b-radius px-4"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    v-model="form.email"
                    required
                  />
                  <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with
                                    anyone else.</small> -->
                </div>
                <div class="form-group">
                  <!-- <label for="password">Password</label> -->
                  <input
                    type="password"
                    class="form-control form-control b-radius px-4"
                    id="password"
                    placeholder="Password"
                    v-model="form.password"
                    required
                  />
                </div>
                <div class="d-flex justify-content-around" v-if="!loading">
                  <button type="submit" class="btn btn-primary px-5">
                    Login
                  </button>
                  <!-- <router-link to="/customers" class="btn btn-primary px-5"
                    >Login</router-link
                  > -->
                  <router-link to="/register" class="btn btn-success px-5"
                    >Register</router-link
                  >
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <router-link to="/forgot-password"
                      >Forgot Password?</router-link
                    >
                  </div>
                </div>
                <div class="d-flex justify-content-around" v-if="loading">
                  <loader-component></loader-component>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  name: "Login",
  data() {
    return {
      loading: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    postLogin: function () {
      this.loading = true;
      let email = this.form.email;
      let password = this.form.password;
      let role = "";

      this.$store
        .dispatch("login", { email, password })
        .then((res) => {
          this.loading = false;
          console.log(res.data.data.data.user.role);
          role = res.data.data.data.user.role;
          Vue.swal(
            res.data.message,
            "Welcome " + res.data.data.data.user.name + "!",
            "success"
          ).then(() => {
            // window.location.reload();
            if (role == "customers") {
              this.$router.push("/customers/my-profile");
            } else if (role == "admin") {
              this.$router.push("/admin/dashboard");
            } else if (role == "warehouse") {
              this.$router.push("/warehouse/dashboard");
            } else if (role == "finance") {
              this.$router.push("/admin-finance/dashboard");
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          Vue.swal(err.response.data.message, err.response.data.data, "error");
          console.log(err.response.data);
        });
    },

    // login() {
    //   const endpoint = "login";
    //   this.$api.post(endpoint, this.form, (status, data, message) => {
    //     if (status === 200) {
    //       var user = data.data.user;
    //       Vue.swal(
    //         "Login Success",
    //         "Welcome " + user.name + "!",
    //         "success"
    //       ).then(() => {
    //         // window.location.reload();
    //         if (user.role == "customers") {
    //           this.$router.push("/customers");
    //         } else if (user.role == "admin") {
    //           this.$router.push("/admin/dashboard");
    //         } else if (user.role == "warehouse") {
    //           this.$router.push("/warehouse/dashboard");
    //         }
    //       });
    //     } else {
    //       console.log(message);
    //     }
    //   });
    // },
  },
};
</script>
